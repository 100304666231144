import { ApiVersion, Channel, Endpoint, SearchQuery } from '@eobuwie/search';

import {
    MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
} from '@configs/megatron-client';

import { USER_BAD_INPUT } from '@types/GraphQLErrorCodes';
import { ERROR_ACTION_TAG_NAME, UNKNOWN_ERROR } from '@types/Errors';

import { getFirstErrorCode } from '@assets/megatron-client/graphql-errors-helper';
import { getMegatronEndpointParameter } from '@assets/get-megatron-endpoint-parameter-search';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.cart.${action}`,
});

const GET_SPONSORED_PRODUCTS_ERROR_TAGS = getErrorTags('getSponsoredProducts');

const getEmptyGetSponsoredProducts = () => ({
    products: [],
    sponsorshipDetails: {},
});

export default ({
    megatronClient,
    $errorHandler,
    normalizeMegatronResponse,
    helpers: { abTests },
}) => ({
    async getSponsoredProducts(
        indexes,
        locale,
        currency,
        selectLocales = [],
        fields = []
    ) {
        try {
            const searchQuery = new SearchQuery({
                apiVersion: ApiVersion.VERSION_5,
                endpoint: Endpoint.BASKET,
            });

            const searchQueryUrl = searchQuery
                .setChannel(Channel.EOBUWIE)
                .setCurrency(currency)
                .setLocale(locale)
                .setSelectLocales(selectLocales)
                .setIndexes(indexes)
                .setSelectFields(fields)
                .setSponsorship()
                .build();

            const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH = getMegatronEndpointParameter(
                abTests
            );

            const response = await megatronClient.get(searchQueryUrl, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH,
            });

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME
            );

            if (!error) {
                const {
                    products = [],
                    sponsorship_details: sponsorshipDetails = {},
                } = data || {};

                return { products, sponsorshipDetails };
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                GET_SPONSORED_PRODUCTS_ERROR_TAGS,
                {
                    searchQueryUrl,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, GET_SPONSORED_PRODUCTS_ERROR_TAGS);
        }

        return getEmptyGetSponsoredProducts();
    },

    async createEmptyCart() {
        const ERROR_TAGS = getErrorTags('createEmptyCart');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/createEmptyCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.createEmptyCart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return null;
    },

    async addConfigurableProductToCart(addToCartRequestData) {
        const response = (cart, errorCode) => ({
            cart: cart || null,
            errorCode: errorCode || null,
        });
        const ERROR_TAGS = getErrorTags('addConfigurableProductToCart');
        const payload = addToCartRequestData;

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/addConfigurableProductsToCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                        hasCfChallenge: true,
                    },
                    withCredentials: true,
                }
            );

            if (!error) {
                return response(data?.addConfigurableProductsToCart?.cart);
            }

            const firstErrorCode = getFirstErrorCode(error.data.errors);

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    firstErrorCode,
                    data,
                    error,
                    status,
                }
            );

            return response(null, firstErrorCode);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return response();
    },

    async removeItemFromCart({ cartId, cartItemId }) {
        const response = (cart, errorCode) => ({
            cart: cart || null,
            errorCode: errorCode || null,
        });
        const ERROR_TAGS = getErrorTags('removeItemFromCart');
        const payload = { cartId, cartItemId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/removeItemFromCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(data?.removeItemFromCart?.cart);
            }

            const firstErrorCode = getFirstErrorCode(error.data.errors);

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    firstErrorCode,
                    data,
                    error,
                    status,
                }
            );

            return response(null, firstErrorCode);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return response();
    },

    async getCart({ cartId, withPaymentAndShippingMethods = false }) {
        const ERROR_TAGS = getErrorTags('getCart');
        const payload = { cartId };

        try {
            const { data, error, status } = await megatronClient.get(
                withPaymentAndShippingMethods
                    ? 'v1/getCartWithPaymentAndShippingMethods'
                    : 'v1/getCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.cart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getCartByPostcode({ cartId, postcode }) {
        const ERROR_TAGS = getErrorTags('getCartByPostcode');
        const payload = { cartId, postcode };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setPostcodeOnQuoteShippingAddress',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.setPostcodeOnQuoteShippingAddress || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async mergeGuestCartWithCustomerCart(sourceCartId, destinationCartId) {
        const ERROR_TAGS = getErrorTags('mergeGuestCartWithCustomerCart');
        const payload = { sourceCartId, destinationCartId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/mergeCarts',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.mergeCarts || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async setShippingAddressOnCart(cartId, shippingAddressInput) {
        const ERROR_TAGS = getErrorTags('setShippingAddressesOnCart');
        const payload = { cartId, shippingAddresses: [shippingAddressInput] };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setShippingAddressesOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.setShippingAddressesOnCart?.cart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async setShippingMethodOnCart(cartId, method) {
        const ERROR_TAGS = getErrorTags('setShippingMethodOnCart');
        const payload = { cartId, shippingMethods: [method] };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setShippingMethodsOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.setShippingMethodsOnCart?.cart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async resetShippingMethodOnCart(cartId) {
        const ERROR_TAGS = getErrorTags('resetShippingMethodOnCart');
        const payload = { cartId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/resetShippingMethodOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.resetShippingMethodOnCart?.cart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async setPaymentMethodOnCart(cartId, paymentMethod) {
        const ERROR_TAGS = getErrorTags('setPaymentMethodOnCart');
        const payload = { cartId, paymentMethod };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setPaymentMethodOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.setPaymentMethodOnCart?.cart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async setBillingAddressOnCart(cartId, billingAddressInput) {
        const ERROR_TAGS = getErrorTags('setBillingAddressOnCart');
        const payload = { cartId, billingAddressInput };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setBillingAddressOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.setBillingAddressOnCart?.cart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async setGuestEmailOnCart(cartId, email) {
        const ERROR_TAGS = getErrorTags('setGuestEmailOnCart');
        const payload = { cartId, email };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setGuestEmailOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                return data?.setGuestEmailOnCart?.cart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async applyCouponToCart(cartId, couponCode) {
        const response = (cart, error, coupon_application_info) => ({
            cart: cart || null,
            error: error || null,
            couponApplicationInfo: coupon_application_info || null,
        });
        const ERROR_TAGS = getErrorTags('applyCouponToCart');
        const payload = { cartId, couponCode };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/applyCouponToCartWithCouponInfo',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(
                    data?.applyCouponToCartWithCouponInfo?.cart,
                    null,
                    data?.applyCouponToCartWithCouponInfo
                        ?.coupon_application_info
                );
            }

            const firstErrorCode = getFirstErrorCode(error.data.errors);

            if (firstErrorCode !== USER_BAD_INPUT) {
                $errorHandler.captureError(
                    new Error(error?.message || UNKNOWN_ERROR, {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        payload,
                        firstErrorCode,
                        data,
                        error,
                        status,
                    }
                );
            }

            return response(null, firstErrorCode);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return response();
    },

    async removeCouponFromCart(cartId) {
        const ERROR_TAGS = getErrorTags('removeCouponFromCart');
        const payload = { cartId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/removeCouponFromCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.removeCouponFromCart?.cart || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getCartItemsVariants(cartId) {
        const ERROR_TAGS = getErrorTags('getCartItemsVariants');
        const payload = { cartId };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getCartItemsVariants',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.cart?.extCart?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async updateCartItem({ cartId, cartItems }) {
        const response = (cart, errorCode) => ({
            cart: cart || null,
            errorCode: errorCode || null,
        });
        const ERROR_TAGS = getErrorTags('updateCartItem');
        const payload = { cartId, cartItems };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/updateCartItems',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(data?.updateCartItems?.cart);
            }

            const firstErrorCode = getFirstErrorCode(error.data.errors);

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    firstErrorCode,
                    data,
                    error,
                    status,
                }
            );

            return response(null, firstErrorCode);
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return response();
    },

    async setInpostPointOnCart(cartId, pointId) {
        const ERROR_TAGS = getErrorTags('setInPostShippingPointOnCart');
        const payload = { cartId, pointId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setInPostShippingPointOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.setInPostShippingPointOnCart?.result || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return false;
    },

    async setStorePickupPointOnCart(cartId, pointId) {
        const ERROR_TAGS = getErrorTags('setStorePickupShippingPointOnCart');
        const payload = { cartId, pointId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setStorePickupShippingPointOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.setStorePickupPlaceOnCart?.result || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return false;
    },

    async setShippingPointOnCart(cartId, pointId) {
        const ERROR_TAGS = getErrorTags('setShippingPointOnCart');
        const payload = { cartId, pointId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setShippingPointOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return !!data?.setShippingPointOnCart?.cart?.extCart
                    ?.shipping_point?.shipping_point_id;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return false;
    },

    async setDpdParcelShopPointOnCart(cartId, pointId) {
        const ERROR_TAGS = getErrorTags('setDpdParcelShopPointOnCart');
        const payload = { cartId, pointId };

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/setDpdParcelShopPointOnCart',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.setDpdParcelShopPointOnCart?.result || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return false;
    },

    async getUpdatedCartItemsStock(cartId) {
        const ERROR_TAGS = getErrorTags('getUpdatedCartItemsStock');
        const payload = { cartId };

        const response = (items = [], totalQuantity = 0) => ({
            items,
            totalQuantity,
        });

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getUpdatedCartItemsStock',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                const {
                    extCart: { items = [] } = {},
                    total_quantity: totalQuantity = 0,
                } = data?.cart || {};

                return response(items, totalQuantity);
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return response();
    },
});
