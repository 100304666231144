import {
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH_GCP,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH_OVH,
} from '@configs/megatron-client';

export const getMegatronEndpointParameter = abTests => {
    if (abTests.getVariant('eob_all_plp_searchGcp') === 'on') {
        return MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH_GCP;
    }

    return MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH_OVH;
};
